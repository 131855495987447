import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Eko from "/src/components/img/eko"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

import Ekoo1 from "/src/components/img/ekoo/1"

export default () => (
  <Layout>
    <SEO title="Úvod do studia ekologie a životního prostředí" />
    <h1>Úvod do studia ekologie a životního prostředí</h1>

    <p>Pojem <strong>ekologie</strong> definoval <strong>Ernest Haeckel v roce 1869</strong> jako soubornou vědu o vztaz&iacute;ch organismů k&nbsp;okoln&iacute;mu světu a k sobě navz&aacute;jem, kam můžeme poč&iacute;tat v&scaron;echny existenčn&iacute; podm&iacute;nky. Pojem poch&aacute;z&iacute; z&nbsp;řečtiny ze slov oikos (domov) a logos (věda).</p>
<p>Podle definice <strong>Krebse z&nbsp;roku 1972</strong> ekologie vědecky studuje interakce, kter&eacute; ovlivňuj&iacute; v&yacute;skyt a hojnost organismů.</p>
<p>Pro &uacute;čely předmětu Ekologie a životn&iacute; prostřed&iacute; lze pak ř&iacute;ci, že ekologie studuje vztahy organismů k&nbsp;okol&iacute; a k&nbsp;sobě navz&aacute;jem. Je to hled&aacute;n&iacute; a tu&scaron;en&iacute; souvislost&iacute;, ekologick&eacute; procesy je velice obt&iacute;žn&eacute; modelovat a vět&scaron;inu ekologick&yacute;ch poznatků z&iacute;sk&aacute;v&aacute;me až po v&yacute;skytu nějak&eacute;ho probl&eacute;mu.</p>
<hr />
<h2>Z&aacute;měna s environmentalistikou aneb co ekologie nen&iacute;</h2>
<p>Častou chybou, souvisej&iacute;c&iacute; s&nbsp;inflac&iacute; pojmu ekologie, je z&aacute;měna ekologie a <strong>environmentalistiky</strong>. Environmentalistika je naukou o životn&iacute;m prostřed&iacute;, zab&yacute;vaj&iacute;c&iacute; se mimo jin&eacute; i ochranou životn&iacute;ho prostřed&iacute;. Souvis&iacute; s&nbsp;n&iacute; i &bdquo;neekologick&aacute;&ldquo; t&eacute;mata, jako např&iacute;klad utv&aacute;řen&iacute; legislativy, utv&aacute;řen&iacute; pracovn&iacute;ho či jin&eacute;ho prostřed&iacute;, etick&eacute;, estetick&eacute;, v&yacute;chovn&eacute; a jin&eacute; ot&aacute;zky atp. Pro vědu zahrnuj&iacute;c&iacute; společensk&eacute; aspekty se už&iacute;v&aacute; označen&iacute; <strong>soci&aacute;ln&iacute; ekologie</strong>.</p>
<Ekoo1 />
<p>Rovněž je třeba rozli&scaron;ovat mezi <strong>ekologem</strong>, zkoumaj&iacute;c&iacute;m vztahy organismů k&nbsp;okol&iacute; a k&nbsp;sobě navz&aacute;jem, a <strong>enviromentalistou</strong> (či <strong>ekologick&yacute;m aktivistou</strong>), kter&yacute; se podle sv&eacute;ho přesvědčen&iacute; snaž&iacute; zabr&aacute;nit devastaci životn&iacute;ho prostřed&iacute;.</p>
<p>Ekologie nen&iacute; ani přes podobn&yacute; n&aacute;zev <strong>etologie</strong>, kter&aacute; se zab&yacute;v&aacute; vztahy vy&scaron;&scaron;&iacute;ch živočichů s&nbsp;okol&iacute;m a navz&aacute;jem.</p>
<hr />
<h2>Historie ekologie</h2>
<h3>Poč&aacute;tky ekologie již v&nbsp;pravěku</h3>
<p>Již od pravěku prob&iacute;h&aacute; spont&aacute;nn&iacute; pozn&aacute;v&aacute;n&iacute; př&iacute;rody, kter&eacute; bylo tehdy nutn&eacute; pro přežit&iacute; člověka. N&aacute;růst ekologick&eacute;ho pozn&aacute;n&iacute; roste pak předev&scaron;&iacute;m od&nbsp;neolitu, což souvis&iacute; s&nbsp;poč&aacute;tkem usedl&eacute;ho života člověka, pěstov&aacute;n&iacute;m plodin a chov&aacute;n&iacute;m dobytka.</p>
<p>V&nbsp;roce 1758 pokl&aacute;d&aacute; &scaron;v&eacute;dsk&yacute; botanik K. Linn&eacute; z&aacute;klady botanick&eacute; a zoologick&eacute; systematiky, kter&aacute; funguje až do dne&scaron;n&iacute;ch dn&iacute; (na z&aacute;kladě rozmnožovac&iacute;ch org&aacute;nů). Až v&nbsp;posledn&iacute; době je zpochybňov&aacute;na nov&yacute;mi poznatky genetiky, změny v&nbsp;syst&eacute;mu v&scaron;ak byly pro zachov&aacute;n&iacute; poř&aacute;dku prozat&iacute;m odm&iacute;tnuty (co bylo do teď druhem, bude j&iacute;m i nad&aacute;le).</p>
<p>D&aacute;le v&nbsp;18. stolet&iacute; jako prvn&iacute; vědecky popisuj&iacute; L. Buffon a J. B. Lamarck poznatky o z&aacute;vislosti organismů na vněj&scaron;&iacute;m prostřed&iacute;. Tito Američan&eacute; měli pro svůj v&yacute;zkum skvěl&eacute; podm&iacute;nky &ndash; v&nbsp;USA byla př&iacute;roda tehdy na rozd&iacute;l od Evropy relativně neporu&scaron;en&aacute;, indi&aacute;ni byli předev&scaron;&iacute;m lovci a sběrači s&nbsp;&uacute;ctou k&nbsp;př&iacute;rodě.</p>
<p>Na přelomu 18. a 19. stolet&iacute; pak A. v. Humboldt velmi komplexně popisuje z&aacute;konitosti roz&scaron;&iacute;řen&iacute; druhů a vlivu vněj&scaron;&iacute;ch podm&iacute;nek na ně, kter&eacute; zjistil na sv&yacute;ch cest&aacute;ch. Vytv&aacute;ř&iacute; podobn&yacute; koncept, jako dnes zn&aacute;m&eacute; vegetačn&iacute; stupně, v&scaron;&iacute;m&aacute; si pak i jin&eacute; stupňovitosti vegetace na z&aacute;větrn&yacute;ch stran&aacute;ch hor.</p>
<p>V&nbsp;19. stolet&iacute; pak Ch. Darwin d&aacute;le popisuje působen&iacute; prostřed&iacute; na druhy, a to předev&scaron;&iacute;m abiotick&eacute;ho. Na z&aacute;kladě těchto poznatků pak mimo jin&eacute; sestavil proslulou evolučn&iacute; teorii &ndash; prvn&iacute; verze v&scaron;ak předpokl&aacute;dala, že jde o kontinu&aacute;ln&iacute; v&yacute;voj vlivem prostřed&iacute;. Zde se v&scaron;ak uk&aacute;zala slabina t&eacute;to verze, protože v&nbsp;n&aacute;lezech chyb&iacute; přechodn&eacute; typy. Jeho n&aacute;sledovn&iacute;ci pak operuj&iacute; s&nbsp;my&scaron;lenkou, že v&yacute;voj jde ve skoc&iacute;ch.</p>
<p><strong>V&nbsp;roce 1869 pak E. Haeckel definuje pojem ekologie</strong> a K. M&ouml;bius v&nbsp;roce 1877 použ&iacute;v&aacute; pojem <strong>biocen&oacute;za</strong> pro soubory různ&yacute;ch druhů.</p>
<p>V&nbsp;roce 1908 F. Dahl definuje pojem <strong>biotop</strong>. Tehdej&scaron;&iacute; n&aacute;hled na biotop, jakožto stanovi&scaron;tě druhu a zapojen&iacute; druhu v&nbsp;př&iacute;rodě živ&eacute;, se v&nbsp;ned&aacute;vně době hlavně d&iacute;ky EU a programu NATURA 2000 posunul sp&iacute;&scaron;e k&nbsp;pohledu na biotop, jakožto společenstvo druhů i se vztahem na prostřed&iacute;.</p>
<h3>Osamostatněn&iacute; ekologie ve 20.&nbsp;stolet&iacute;</h3>
<p>Ekologie byla vn&iacute;m&aacute;na jako souč&aacute;st biologie, ve 20. stolet&iacute; kolem 1. světov&eacute; v&aacute;lky v&scaron;ak doch&aacute;z&iacute; k&nbsp;jej&iacute;mu postupn&eacute;mu osamostatněn&iacute; jakožto samostatn&eacute; vědn&iacute; discipl&iacute;ny. Od 30. let se objevuj&iacute; snahy o komplexn&iacute; studium cel&yacute;ch společenstev.</p>
<p>V&nbsp;souvislosti s&nbsp;t&iacute;m v roce 1935 anglick&yacute; ekolog A. G. Tansley použ&iacute;v&aacute; pojem <strong>ekosyst&eacute;m</strong> a v&nbsp;roce 1942 sovětsk&yacute; ekolog A. N. Sukačev obsahově bl&iacute;zk&yacute; term&iacute;n <strong>biogeocen&oacute;za</strong>, v&nbsp;němž je již dle jm&eacute;na zdůrazněna role biotick&eacute; (bio) i abiotick&eacute; (geo) složky.</p>
<p>Československ&yacute; ekolog Zlatn&iacute;k, kter&yacute; se v&iacute;ce věnoval abiotick&yacute;m podm&iacute;nk&aacute;m a kter&yacute; byl tehdy pověřen děl&aacute;n&iacute;m lesn&iacute; klasifikace na Podkarpatsk&eacute; Rusi, upravil pojem biogeocen&oacute;za na <strong>geobiocen&oacute;zu</strong>. Jeho nov&yacute; syst&eacute;m byl lesn&iacute;ky v&nbsp;Československu použ&iacute;v&aacute;n do 70. let, kdy byla zavedena nov&aacute; typologie (lesn&iacute; typy).</p>
<p>Nicm&eacute;ně, zp&aacute;tky do světa. V&nbsp;60. letech doch&aacute;z&iacute; k&nbsp;velk&eacute;mu rozvoji ekologie, mezi hlavn&iacute; t&eacute;mata patř&iacute; produkce ekosyst&eacute;mů, koloběh l&aacute;tek a praktick&eacute; probl&eacute;my životn&iacute;ho prostřed&iacute; (zneči&scaron;těn&iacute;, okyselov&aacute;n&iacute; prostřed&iacute;).</p>
<p>V&nbsp;souvislosti s&nbsp;probl&eacute;mem hladomoru v&nbsp;Africe byl vyhl&aacute;&scaron;en roku 1965 program <strong>Man and the Biosphere</strong> (Mezin&aacute;rodn&iacute; biologick&yacute; program). Zab&yacute;val se popisem ekosyst&eacute;mů ve světě a jejich &bdquo;v&yacute;těžnost&iacute;&ldquo;. Souč&aacute;st&iacute; byl v&yacute;zkum produkce ekosyst&eacute;mů a koloběhu l&aacute;tek, rovněž byla v&nbsp;r&aacute;mci programu ře&scaron;ena devastace ekosyst&eacute;mů.</p>
<p>Program trval 10 let a konč&iacute; tud&iacute;ž v&nbsp;roce 1975. Jeho v&yacute;sledkem byla mimo jin&eacute; <strong>zelen&aacute; revoluce</strong>, d&iacute;ky n&iacute;ž bylo možn&eacute; ekosyst&eacute;my intenzivněji využ&iacute;vat a do&scaron;lo tak v&iacute;cem&eacute;ně k&nbsp;ukončen&iacute; hladomorů v&nbsp;Africe. To umožnilo populačn&iacute; explozi, v&nbsp;současn&eacute; době v&scaron;ak maj&iacute; ekosyst&eacute;my opět probl&eacute;m &bdquo;ut&aacute;hnout&ldquo; n&aacute;růst obyvatel a doch&aacute;z&iacute; i z&nbsp;těchto důvodů k&nbsp;migraci do Evropy.</p>
<hr />
<h2>Ekologie dnes</h2>
<p>V&nbsp;ČR zaž&iacute;v&aacute; ekologie explozi až po revoluci, a to mimo jin&eacute; i d&iacute;ky př&iacute;sunu informac&iacute; a studi&iacute;. <strong>Pojem ekologie je v&scaron;ak v&nbsp;současnosti inflačn&iacute;</strong> a v&nbsp;oč&iacute;ch veřejnosti zahrnuje v&scaron;e, co souvis&iacute; s&nbsp;životn&iacute;m prostřed&iacute;m.</p>
<p>Mezi probl&eacute;my, kter&eacute; ekologie ře&scaron;&iacute;, patř&iacute; (podtrženy ty, na kter&eacute; tento předmět zaměřen):</p>
<ol>
<li><u>tolerance a adaptace organismů k&nbsp;prostřed&iacute;</u></li>
<li><u>ekosyst&eacute;m, jeho struktura a funkce</u></li>
<li><u>progn&oacute;zov&aacute;n&iacute; v&yacute;voje ekosyst&eacute;mů</u></li>
<li>ekologick&eacute; podm&iacute;nky roz&scaron;&iacute;řen&iacute; organismů na Zemi</li>
<li>časoprostorov&eacute; změny v&yacute;skytu, početnosti a aktivity organismů</li>
<li>vz&aacute;jemn&eacute; vztahy organismů v&nbsp;populac&iacute;ch a společenstvech, evoluce těchto vztahů</li>
<li>produktivita ekosyst&eacute;mů, produkce a rozklad</li>
</ol>
<p>Ekologie pak navazuje na jin&eacute; vědy, nam&aacute;tkou na etologii, parazitologii, epidemiologii, systematiku organismů a evolučn&iacute; biologii, klimatologii, hydrologii, pedologi&iacute;, enviromentalistiku, soci&aacute;ln&iacute; ekologii a dal&scaron;&iacute;.</p>
<p>Při vyhodnocov&aacute;n&iacute; jevů pak využ&iacute;v&aacute; postupy matematiky, obecn&eacute; teorie syst&eacute;mů a kybernetiky.</p>

    <hr />
    <ImgCon><Eko /><div>
    <h2>Studijn&iacute; materi&aacute;ly Ekologie a ŽP</h2>
    <p>T&eacute;ma <strong>Úvod do studia ekologie a životního prostředí</strong> je souč&aacute;st&iacute; <Link to="/ekologie-zivotni-prostredi/">studijn&iacute;ch materi&aacute;lů Ekologie a životní prostředí</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte n&aacute;sleduj&iacute;c&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/ekologie-zivotni-prostredi/"><button>Rozcestník ekologie a ŽP</button></Link>
    <Link to="/ekologie-zivotni-prostredi/zivotni-vzrustove-formy/"><button className="inv">Životní a vzrůstové formy rostlin &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
